<template>
    <div class="MyDeityPage">
        <div class="head">
            <div class="picture">
                <img :src="item.USER_PIC ? require(`@/assets/profiles/${item.USER_PIC}`) : require('@/assets/img/default.png')" />
            </div>

            <p class="title">{{ item.NICK_NAME }}</p>
        </div>

        <div class="details">
            <p class="title">
                <span class="iconfont icon-a-iconpay_huaban1fuben4"></span>
                <span>卖单信息：</span>
            </p>

            <p class="sell">
                <span>出售总数：</span>
                <span>$ {{ item.AMT }}</span>
            </p>

            <p class="surplus">
                <span>剩余总数：</span>
                <span>$ {{ item.AMT_SELL }}</span>
            </p>

            <p class="split">
                <span>是否拆分：</span>
                <span>{{ item.IS_SPLIT === '1' ? '拆分' : '不拆分' }}</span>
            </p>

            <p>
                <span>备注：</span>
                <span>{{ item.REMARK }}</span>
            </p>
        </div>

        <van-button class="btn" :class="{ bottom: item.ORDER_STATE !== '0' }" type="primary" block round disabled>{{ item.order_state }}</van-button>
        <van-button v-if="item.ORDER_STATE === '0'" class="btn1" color="#eb220b" type="primary" block round @click="cancelBtn">订单取消</van-button>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { SellReturnOrder } from '@/api'
import { Dialog, Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            fileConfig: '',
            item: {},
            subID: ''
        })

        const router = useRouter()
        const route = useRoute()
        const store = useStore()

        state.fileConfig = store.state.fileConfig

        state.item = route.query

        const cancelBtn = () => {
            SellReturnOrder(state.item.ID).then(res => {
                if (res.status) {
                    Toast.success(res.message)
                    router.back()
                } else {
                    Toast.fail(res.message)
                }
            })
        }

        return {
            ...toRefs(state),
            router,
            cancelBtn
        }
    }
}
</script>

<style lang="scss" scoped>
.MyDeityPage {
    background: $background;
    position: relative;

    .head {
        width: 100%;
        height: h(214);
        text-align: center;
        line-height: h(150);
        font-size: 30px;
        color: #ffffff;
        background: #3366ee;
        background-image: url('~@/assets/img/help-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom-left-radius: 20%;
        border-bottom-right-radius: 20%;

        .picture {
            position: absolute;
            top: h(50);
            left: w(15);
            height: h(50);
            border-radius: 50%;
            overflow: hidden;

            img {
                width: h(50);
                height: h(50);
            }
        }

        .title {
            position: absolute;
            top: h(60);
            left: w(65);
            line-height: h(30);
            font-size: 14px;
            color: #ffffff;
        }
    }

    .details {
        margin: h(-80) auto 0;
        width: w(346);
        background: #ffffff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: h(15);

        font-size: 12px;
        color: #828282;

        p {
            margin-top: h(16);

            & span:last-child {
                margin-left: w(15);
                color: #333333;
            }
        }

        & p:first-child {
            margin-top: 0;
        }

        .title {
            font-size: 14px;
            color: #000000;

            .icon-a-iconpay_huaban1fuben4 {
                margin-right: w(10);
            }
        }
    }

    .btn {
        @include btn();

        position: absolute;
        bottom: h(100);
        left: 0;
        right: 0;
    }
    .btn1 {
        @include btn();
        position: absolute;
        bottom: h(30);
        left: 0;
        right: 0;
    }

    .bottom {
        bottom: h(30) !important;
    }
}
</style>
